import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import LinearProgress from '@material-ui/core/LinearProgress'
import * as cornerstone from "cornerstone-core"
import * as cornerstoneWADOImageLoader from "cornerstone-wado-image-loader"
import {
  //allFilesStore,
  filesStore,
} from '../actions'
import {
  getDicomPatientName,
  getDicomStudyId,
  getDicomStudyDate,
  getDicomStudyTime,
  getDicomStudyDescription,
  getDicomSeriesDate,
  getDicomSeriesTime,
  getDicomSeriesDescription,
  getDicomSeriesNumber,
  getDicomInstanceNumber,
  getDicomSliceLocation,
  getDicomSliceDistance,
  getDicomRows,
  getDicomColumns,
  getDicomEchoNumber,
  getFileNameCorrect,
  dicomDateTimeToLocale,
} from '../functions'

import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import axios from 'axios';

cornerstoneWADOImageLoader.external.cornerstone = cornerstone

class OpenMultipleFilesDlg extends PureComponent {

  state = {
    progress: 0,
    cancel: false,
  }



  componentDidMount = async () => {
    //console.log('OpenMultipleFilesDlg - componentDidMount: ', this.props.files)
    const url = new URL(document.location.href);
    const params = new URLSearchParams(url.search);
    this.items = []
    this.count = 0
    this.step = 0

    const files = this.props.files

    this.step = files.length / 50
    this.nextProgress = this.step
    this.t0 = performance.now()

    //console.log('OpenMultipleFilesDlg - cachedImages: ', cornerstone.imageCache.cachedImages)

    //cornerstoneWADOImageLoader.wadouri.fileManager.purge()
    //cornerstone.imageCache.purgeCache()

    let imageIds = []
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      if (this.props.origin === 'local') {
        let fileBlo;
        if (file.typeZip) {
          let blob = await file.async('blob');
          fileBlo = new File([blob], file.name, {
            type: blob.type,
            lastModified: Date.now()
          });
        }
        else {
          fileBlo = file;
        }

        imageIds.push(cornerstoneWADOImageLoader.wadouri.fileManager.add(fileBlo));
      }
      else {
        imageIds.push(cornerstoneWADOImageLoader.wadouri.fileManager.addBuffer(file.data))
      }
    }
    //console.log('OpenMultipleFilesDlg - files: ', files)
    const fileUpload = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (this.state.cancel) {
        //this.props.setAllFilesStore(null)
        this.props.setFilesStore(null)
        this.close()
        return
      }

      //console.log('imageId', imageIds[i])
      this.LoadImageFromFile(imageIds[i], file, files.length).then((e) => {
        if (e) {
          fileUpload.push(file);
          if (this.count === files.length) {
            if (!params.get("link")) {
              this.handleZip(fileUpload);
            }
          }
        }
      });

    }

    //this.close()
  }

  LoadImageFromFile = (imageIds, file, filesLength) => {
    return new Promise((resulve, reject) => {
      cornerstone.loadImage(imageIds)
        .then((image) => {
          //console.log('image', image)
          const patientName = getDicomPatientName(image)

          const studyId = getDicomStudyId(image)
          const studyDate = getDicomStudyDate(image)
          const studyTime = getDicomStudyTime(image)
          const studyDescription = getDicomStudyDescription(image)

          const seriesDate = getDicomSeriesDate(image)
          const seriesTime = getDicomSeriesTime(image)
          const seriesDescription = getDicomSeriesDescription(image)
          const seriesNumber = getDicomSeriesNumber(image)

          const instanceNumber = getDicomInstanceNumber(image)
          const sliceDistance = getDicomSliceDistance(image)
          const echoNumber = getDicomEchoNumber(image)
          const sliceLocation = getDicomSliceLocation(image)
          const columns = getDicomColumns(image)
          const rows = getDicomRows(image)

          const studyDateTime = studyDate === undefined ? undefined : dicomDateTimeToLocale(`${studyDate}.${studyTime}`)

          let item = null
          if (this.props.origin === 'local')
            item = {
              imageId: imageIds,
              instanceNumber: instanceNumber,
              name: getFileNameCorrect(file.name),
              image: image,
              rows: rows,
              columns: columns,
              sliceDistance: sliceDistance,
              sliceLocation: sliceLocation,
              patient: {
                patientName: patientName
              },
              study: {
                studyId: studyId,
                studyDate: studyDate,
                studyTime: studyTime,
                studyDateTime: studyDateTime,
                studyDescription: studyDescription
              },
              series: {
                seriesDate: seriesDate,
                seriesTime: seriesTime,
                seriesDescription: seriesDescription,
                seriesNumber: seriesNumber,
                echoNumber: echoNumber
              }
            }
          else
            item = {
              imageId: imageIds,
              instanceNumber: instanceNumber,
              name: file.name,
              image: image,
              rows: rows,
              columns: columns,
              sliceDistance: sliceDistance,
              sliceLocation: sliceLocation,
              patient: {
                patientName: patientName
              },
              study: {
                studyId: studyId,
                studyDate: studyDate,
                studyTime: studyTime,
                studyDateTime: studyDateTime,
                studyDescription: studyDescription
              },
              series: {
                seriesDate: seriesDate,
                seriesTime: seriesTime,
                seriesDescription: seriesDescription,
                seriesNumber: seriesNumber,
                echoNumber: echoNumber
              }
            }
          this.items.push(item)
          this.count++

          const progress = Math.floor(this.count * (100 / filesLength))
          //
          if (progress > this.nextProgress) {
            this.nextProgress += this.step
            this.setState({ progress: progress })
          }
          if (this.count === filesLength) {
            this.items.sort((l, r) => {
              resulve(true);
              return l.instanceNumber - r.instanceNumber
              // return l.sliceDistance - r.sliceDistance
              // return l.sliceLocation - r.sliceLocation
            })
            this.t1 = performance.now()
            console.log(`performance load ${this.count} images in ${this.t1 - this.t0} milliseconds`)
            this.props.setFilesStore(this.items)
            this.close();
          }
          resulve(true);
        }, (e) => {
          resulve(false);
          console.log('Error in reading multiple files: ', e)
          this.count++
        })
    })
  }
  close = () => {
    //console.log('this.slicesDistance: ', this.slicesDistance)
    this.props.onClose()
  }

  handleZip = (files) => {
    this.props.onHandleZip(files);
  }

  cancel = () => {
    this.setState({ cancel: true })
  }

  render() {
    return (
      <div>
        <Dialog
          open={true}
          onClose={this.close}
          aria-labelledby="alert-dialog-title"
        >
          <DialogTitle id="alert-dialog-title">{"Opening multiple files ... " + this.state.progress + "%"}</DialogTitle>
          <DialogContent>
            <LinearProgress variant="determinate" value={this.state.progress} color='secondary' />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.cancel}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <a>asdasdasd</a>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    //setAllFilesStore: (files) => dispatch(allFilesStore(files)),
    setFilesStore: (files) => dispatch(filesStore(files)),
  }
}

export default connect(null, mapDispatchToProps)(OpenMultipleFilesDlg)
